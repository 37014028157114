import $ from "jquery";

export default class PopUpFixed {

    constructor($receiver) {
        this.$receiver = $receiver;
        this.popUp = new PopUp(this);
        return this;
    }

}

class PopUp {

    constructor(factory) {
        this.factory = factory;
        this.$popUp = $('#pop-up-fixed');
        this.$popUpClose = $('#pop-up-fixed-close');
        this.initPopUpEvents();
    }

    initPopUpEvents() {
        this.factory.$receiver.click(() => this.mediaOnClick());
        this.$popUpClose.click(() => this.closePopUp());
    }

    mediaOnClick() {
        let $content = this.factory.$receiver.find('.clickable-fullscreen-content');
        if ($content.is('video'))
            this.videoFullscreen($content[0]);
        else 
            this.openPopUp();
    }

    videoFullscreen(video) {
        if (video.requestFullscreen) 
            video.requestFullscreen();
        else if (video.mozRequestFullScreen)
            video.mozRequestFullScreen();
        else if (video.webkitRequestFullscreen) 
            video.webkitRequestFullscreen();
    }

    openPopUp() {
        this.$popUp.css('display', 'initial');
        let $content = this.factory.$receiver.find('.clickable-fullscreen-content');
        $('#pop-up-fixed-body').empty().append($content.clone());
    }

    closePopUp() {
        this.$popUp.css('display', 'none');
    }

}