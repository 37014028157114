import $ from "jquery";

export default class AbstractSitemap {

    constructor() {
        this.editable = false;
        this.postURL = '/api/v1/sitemap/edit';
        this.showTriggers();
        this.initEditOnClick();
        this.initOnSubmit();
    }

    showTriggers() {
        $('#sitemap-edit-trigger').removeClass('hide');
    }

    initEditOnClick() {
        $('#sitemap-edit-trigger').click(() => {
            this.toggleEditor();
        });
    }

    toggleEditor() {
        if (!this.editable)
            this.toggleShowEditor();
        else 
            this.toggleHideEditor();
    }

    toggleShowEditor() {
        this.disableLink();
        $('.sitemap-part span').addClass('hide');
        $('.sitemap-part input').removeClass('hide');
        $('.sitemap-part-no-submenu span').addClass('hide');
        $('.sitemap-part-no-submenu input').removeClass('hide');
        $('#sitemap-submit-trigger').removeClass('hide');
        $('.sitemap-part button').removeClass('hide');
        this.editable = true;
    }

    toggleHideEditor() {
        this.enableLink();
        $('.sitemap-part input').addClass('hide');
        $('.sitemap-part span').removeClass('hide');
        $('.sitemap-part-no-submenu span').removeClass('hide');
        $('.sitemap-part-no-submenu input').addClass('hide');
        $('#sitemap-submit-trigger').addClass('hide');
        $('.sitemap-part button').addClass('hide');
        this.editable = false;
    }

    disableLink() {
        $('.sitemap-part a').click((event) => event.preventDefault());
        $('.sitemap-part-no-submenu a').click((event) => event.preventDefault());
    }

    enableLink() {
        $('.sitemap-part a').unbind('click');
        $('.sitemap-part-no-submenu a').unbind('click');
    }

    initOnSubmit() {
        $('#sitemap-submit-trigger').click(() => this.onSubmit());
    }

    onSubmit() {
        let _this = this;
        let payload = [];
        $('.sitemap-part').each(function() {
            let menuVal = _this.getMenuInputVal($(this));
            let menu = {
                id: menuVal.id,
                name: menuVal.name, 
                url: menuVal.url
            };

            let subMenus = _this.getSubMenuInputVal($(this));
            if (subMenus.length != 0) {
                menu.subMenus = subMenus;
            }
            payload.push(menu);
        });
        $('.sitemap-part-no-submenu').find('h3 input').each(function() {
            let $noSubmenu = $(this);
            payload.push({
                id: $noSubmenu.data('id'),
                name: $noSubmenu.val(),
                url: $noSubmenu.data('url'),
                subMenus: []
            })
        });
        this.submitPayload(payload);
    }

    getMenuInputVal($sitemapPart) {
        let $menuInput = $sitemapPart.find('h3 input');
        return {
            id: $menuInput.data('id'),
            name: $menuInput.val(),
            url: $menuInput.data('url')
        }
    }

    getSubMenuInputVal($sitemapPart) {
        let subMenus = [];
        let $li = $sitemapPart.find('ul li');
        $li.each(function() {
            let $input = $(this).find('input');
            subMenus.push({ 
                id: $input.data('id'),
                name: $input.val(),
                url: $input.data('url')
            });
        });
        return subMenus;
    }

    submitPayload(payload) {
        $.ajax({
            type: "POST",
            contentType: "application/json",
            url: this.postURL,
            data: JSON.stringify(payload),
            headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
            success: (response) => {
                if (response.statusCode == 200) {
                    window.alert('success');
                    window.location.reload();
                } else {
                    window.alert('something went wrong')
                }
            },
            error: () => window.alert('something went wrong'),
            dataType: 'json'
        });
    }
}