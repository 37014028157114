import $ from "jquery";

export default class Sitemap {
    
    constructor() {
        this.$sitemapParts = $('.sitemap-part');
        this.$sitemapContainer = $('.sitemap-container');
        this.noSubmenus = [];
        this.groupNoSubMenus();
    }

    groupNoSubMenus() {
        this.putAndTakeNoSubMenus();
        this.renderNoSubMenusIntoGroup();
    }

    putAndTakeNoSubMenus() {
        let _this = this;
        this.$sitemapParts.each(function(index) {
            if ($(this).find('li').length == 0) {
                _this.noSubmenus.push(_this.getMenuInputVal($(this)));
                $(this).remove();
            }
        });
    }

    renderNoSubMenusIntoGroup() {
        let html = '<div class="sitemap-part-no-submenu">';
        this.noSubmenus.forEach((noSubMenu) => {
            html += ` <h3><a href="${noSubMenu.url}"><span>${noSubMenu.name}</span><input type="text" value="${noSubMenu.name}" data-id="${noSubMenu.id}" data-url="${noSubMenu.url}" class="hide"></a></h3>`;
        });
        html += '</div>';
        this.$sitemapContainer.append(html);
    }
    
    getMenuInputVal($sitemapPart) {
        let $menuInput = $sitemapPart.find('h3 input');
        return {
            id: $menuInput.data('id'),
            name: $menuInput.val(),
            url: $menuInput.data('url')
        }
    }
}