import $ from "jquery";

export default class AbstractEditorDeleteableFactory {

    constructor($deleteButton) {
        this.$deleteButton = $deleteButton;
        this.deleteURL = '/api/v1/content/delete/' + $deleteButton.data('id');
        $deleteButton.css('display', 'initial');
        this.initDeleteEvent();
        return this;
    }

    initDeleteEvent() {
        this.$deleteButton.click(() => {
            let confirm = window.confirm("Are you sure?");
            if (confirm) 
                this.deleteArticle();
        })

    }

    deleteArticle() {
        $.ajax({
            url: this.deleteURL,
            type: 'DELETE',
            headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
            success: (response) => { 
                window.alert('success')
                window.location.reload();
            },
            error: () => {},
            contentType: 'json'
        });
    }
    

}
