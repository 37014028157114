import $ from "jquery";

export default class AbstractEditorFactory {

    constructor(triggerId) {
        this.$trigger = $(`#${triggerId}`);
        this.category = this.$trigger.data('category');
        this.domFile;
        this.domInputImageURL;
        this.$inputTitle;
        this.$buttonSubmit;
        this.$buttonImage;
        this.$buttonImageBrowse;
        this.$feedback;
        return this;
    }

    initAdditionalVariables(trigger) {}

    initEvents() {}

    initEditor() {}

    getPayload() {
        return JSON.stringify({
            category: this.category,
            title: this.$inputTitle.val(),
            content: this.content
        })
    }

    initSaveButton() {
        this.$saveButton.click((event) => {
            event.preventDefault();
            this.content = tinymce.activeEditor.getContent();
            let json = this.getPayload();
            $.ajax({
                type: "POST",
                contentType: "application/json",
                url: this.postURL,
                data: json,
                headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
                success: (response) => this.saveButtonSuccessCallback(response),
                error: () => this.$feedback.text('Something Went Wrong').addClass('failed'),
                dataType: 'json'
            });
        });
    }

    saveButtonSuccessCallback(response) {
        if (response.statusCode == 200) {
            this.$feedback.text('Success').addClass('success').addClass('feedback-success');
            window.setTimeout(() => {
                this.reset();
                window.location.reload();
            }, 3000);
        } else {
            this.$feedback.text('Something Went Wrong').addClass('failed');
        }
    }

    initUploadImage() {
        this.domFile.onchange = () => {
            this.domInputImageURL.value = 'uploading...';
            var formData = new FormData();
            formData.append('file', this.domFile.files[0]);
            $.ajax({
                url: '/api/v1/content/addImage',
                type: 'POST',
                headers: { 'SESSION_ID': window.sessionStorage.getItem('sessionId') },
                success: this.showSuccessUploadImageResult(),
                error: this.showErrorUploadImageResult(),
                data: formData,
                processData: false,
                contentType: false
            });
        }
        this.$buttonImage = $('.mce-i-image');
        this.$buttonImage.click(() => {
            window.setTimeout(() => {
                this.$buttonImageBrowse = $('.mce-i-browse');
                this.$buttonImageBrowse.parent().click(() => {
                    let domInputImageURL = this.getInputImageURL();
                    domInputImageURL.value = 'opening file browser...';
                    this.$hiddenInputImage.trigger('click');
                });
            }, 300);
        });
    }

    getInputImageURL() {
        let domInputImageURL;
        let domInputs = document.getElementsByClassName('mce-textbox');
        for(let i = 0; i < domInputs.length; i++) {
            let domInput = domInputs[i];
            if(~domInput.id.indexOf('-inp')) {
                domInputImageURL = domInput;
            }
        }
        this.domInputImageURL = domInputImageURL;
        return domInputImageURL;
    }

    showSuccessUploadImageResult(results) {
        return (results) => {
            this.domInputImageURL.value = results.payload.url;
            this.resetFileInput();
        }
    }

    showErrorUploadImageResult(response) {
        return (response) => {
            if(typeof response.feedback === 'undefined') {
              response = {};
              response.feedback = 'Uploaded file target is not found';   
            }
            this.domInputImageURL.value = response.feedback;
            this.resetFileInput();
        }
    }

    resetFileInput() {
        this.domFile.value = "";
        this.$feedback.text('');
    }

    reset() {
        this.$saveButton.prop('disabled', false);
        this.$feedback.removeClass('success');
        this.$feedback.removeClass('failed');
    }
}
