import $ from "jquery";

export default class Slider {
    
    constructor() {
       // this.mockImages();
        this.images = SLIDERS;
        this.currentImageId = this.images.length > 0 ? this.images[0].id : null;
        this.isDeleteDialogActive = false;
        this.deleteURL = ServerVariables.URL + 'api/v1/content/deleteSlider';
        return this;     
    }

    withDomId(domId) {
        this.domId = domId;
        return this;
    }

    construct() {
        this.constructDom();
        try {
            this.ifOnlyOneImage(() => { 
                this.hideReceiver();
                this.initRemoveEvent();
            });
            this.ifManyImages(() => this.initSliderReceivers());
            this.run();
        } catch (Exception) {
            this.hideReceiver();
        }
    }

    constructDom() {
        this.$dom = $(`#${this.domId}`);
        return this;
    }

    ifOnlyOneImage(callback) {
        if (this.images.length === 1) {
            callback();
        }
    }

    ifManyImages(callback) {
        if (this.images.length > 1) {
            callback();
        }
    } 

    mockImages() {
        this.images = [
            { url: 'public/images/dev/bali_2.jpg', brightness: 'dark' },
            { url: 'public/images/dev/albert-einstein.jpg', brightness: 'dark' }
        ]
    }

    run() {
        this.index = 0;
        this.setImage(this.images[this.index]);
        window.setInterval(() => {
            this.setImage(this.images[this.index])
            this.index = this.index === this.images.length - 1 ? 0: this.index + 1;
        }, 5000);
        return this;
    }

    setImage(image) {
        if (!this.isDeleteDialogActive) {
            this.setBackgroundImage(image.url);
            this.currentImageId = image.id;
            if(this.isBackgroundImageBright(image.brightness)) 
                this.setNavClassDark();   
            else 
                this.removeNavClassDark();
        }
    }

    setBackgroundImage(url) {
        this.$dom.css('background-image', `url(${url})`);
        this.$dom.css('background-attachment', `fixed`);
        this.$dom.css('background-repeat', `no-repeat`);
        this.$dom.css('background-size', `100%`);
    }

    isBackgroundImageBright(color) {
        if (color === 'bright')
            return true;
        else 
            return false;
    }

    setNavClassDark() {
        $('#nav-top').addClass('nav-dark');
    }

    removeNavClassDark() {
        $('#nav-top').removeClass('nav-dark');
    }

    initSliderReceivers() {
        this.unHideReceiver();
        this.initArrowEvent();
        this.initRemoveEvent();
    }

    initArrowEvent() {
        $('.icon-arrow-right').click(() => {
            this.index = this.index === this.images.length - 1 ? 0: this.index + 1;
            this.setImage(this.images[this.index]);
        });

        $('.icon-arrow-left').click(() => {
            this.index = this.index === 0 ? this.images.length - 1: this.index - 1;
            this.setImage(this.images[this.index])
        });
    }

    initRemoveEvent() {
        $('#remove-slider').click(() => {
            this.isDeleteDialogActive = true;
            let confirm = window.confirm("Are you sure?");
            if (confirm) 
                this.deleteSlider();
            else
                this.isDeleteDialogActive = false;
        });
    }

    deleteSlider() {
        $.ajax({
            url: this.deleteURL + '/' + this.currentImageId,
            type: 'DELETE',
            headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
            success: (response) => { 
                window.alert('success')
                this.isDeleteDialogActive = false;
                window.location.reload();
            },
            error: () => {},
            contentType: 'json'
        });
    }

    hideReceiver() {
        $('.icon-arrow-right').addClass('hide');
        $('.icon-arrow-left').addClass('hide');
    }

    unHideReceiver() {
        $('.icon-arrow-right').removeClass('hide');
        $('.icon-arrow-left').removeClass('hide');
    }

}