import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractBinderFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.category = $addNewButton.data('category');
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        $('#remove-slider').css('display', 'inline-block');
        this.initClickEvents();
        return this;
    }

    bindStandaloneSliderUploader(standaloneSliderUploader) {
        this.standaloneSliderUploader = standaloneSliderUploader;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
        this.$addNewButton.click(() => {
            this.standaloneSliderUploader.category = this.category;
            $('#pop-up-slider-title').text(`Add New ${this.category} Slider`);
            
        });
    }    
}
