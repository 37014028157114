import $ from "jquery";
import SmoothScroll from "smooth-scroll";

export default class SideNavigation {

	constructor() {
		SmoothScroll.init({
            easing: 'easeInOutCubic',
            speed: 1000
        });
        this.initScrollFunction();
	}

    initScrollFunction() {
        let _this = this;
        $(window).scroll(function(event) {
            $('.app-content').each(function () {
                let element = $(this)[0];
                let $current = $('#nav-ul-' + element.id);
                if ($('.sitemap')[0].getBoundingClientRect().top < 500) {
                    _this.resetAllActiveNav();
                    _this.hideSideNav();
                } else if($(this)[0].getBoundingClientRect().top < 10) {
                    _this.resetAllActiveNav();
                    _this.activateCurrent($current);
                    _this.showOrHideNavByCurrentId(element.id);
                    _this.assignNavColorByBrightness(element.dataset['color']);
                }
            })
        });
    }

    resetAllActiveNav() {
        $('#nav-ul li').removeClass('active');
    }

    activateCurrent($nav) {
        $nav.addClass('active');
    }

    showOrHideNavByCurrentId(id) {
        if (id !== 'ipho-2017' && id !== 'cover' && id !== 'cover-ipho2017') 
            this.showSideNav();    
        else 
            this.hideSideNav();
    }
    
    showSideNav() {
        $('#nav-ul').addClass('circle-active');
        $('#nav-ul').css('visibility', 'visible');
    }

    hideSideNav() {
        $('#nav-ul').css('visibility', 'hidden');
        $('#nav-ul').removeClass('circle-active');
    }

    assignNavColorByBrightness(color) {
        if (color === 'bright') 
            $('#nav-ul').addClass('bright');
        else 
            $('#nav-ul').removeClass('bright');
    }

}