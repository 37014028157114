import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractCreationalFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        this.category = $addNewButton.data('category');
        this.initClickEvents();
        return this;
    }

    bindStandaloneEditor(standaloneEditor) {
        this.standaloneEditor = standaloneEditor;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
        this.$addNewButton.click(() => {
            this.standaloneEditor.resetAll();
            this.standaloneEditor.category = this.category;
            $('#pop-up-title').text(`Add ${this.category}`);
        });
    }    
}
