import $ from "jquery";

export default class ScriptController {

    constructor() {}

    ifPage(pageName, callback) {
        if($('#app').data('page') === pageName) {
            callback();
        }
    }

    ifRoleIsContent(callback) {
        if (ServerVariables.Auth.role === 'content') {
            callback();
        }
    }

}