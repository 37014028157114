import $ from "jquery";

export default class AbstractFileUploaderFactory {

    constructor() {
        this.category;
        this.domFileCover;
        this.domFileContent;
        this.$inputTitle = $('#input-file-upload-title');
        this.$submitButton = $('#input-file-upload-submit');
        this.$feedback = $('#input-file-upload-feedback') ;
        this.postURL = ServerVariables.URL + 'api/v1/content/addFileArticle';
        this.initSaveButton();
        return this;
    }

    initSaveButton() {
        this.$submitButton.click((event) => {
            event.preventDefault();

            var formData = new FormData();
            this.domFileCover = document.getElementById('input-file-upload-cover');
            this.domFileContent = document.getElementById('input-file-upload-content');
            formData.append('title', this.$inputTitle.val());
            formData.append('category', this.category);
            formData.append('cover', this.domFileCover.files[0]);
            formData.append('file', this.domFileContent.files[0]);
            
            this.$feedback.text('saving...');
            $.ajax({
                url: this.postURL,
                type: 'POST',
                headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
                success: (response) => this.saveButtonSuccessCallback(response),
                error: () => this.$feedback.text('Something Went Wrong').addClass('failed'),
                dataType: 'json',
                data: formData,
                processData: false,
                contentType: false
            });
            this.$submitButton.prop('disabled' , true);
        });
    }

    saveButtonSuccessCallback(response) {
        if (response.statusCode == 200) {
            this.$submitButton.prop('disabled' , false);
            this.$feedback.text('Success').addClass('success feedback-success');
            window.setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
            this.$feedback.text('Something Went Wrong').addClass('failed');
        }
    }
    

}
