import $ from "jquery";

export default class Auth{

    constructor() {
        return this;
    }

    initEvents() {
        this.initLoginEvent();
    }

    initLoginEvent() {
        $('#login').submit((event) => {
            event.preventDefault();

            let username = $('#username').val();
            let password = $('#password').val();
            let json = JSON.stringify({
                username: username,
                password: password
            });
            console.log(username);
            if (username === 'content' || username === 'mims') 
                this.loginContent(json);
            else 
                this.loginParticipant(json);
        });
    }

    loginParticipant(json) {
            $.ajax({
                type: "POST",
                url: "/api/fullreg/login",
                data: json,
                contentType: "application/json; charset=utf-8",
                success: (response, status, request) => {
                    if (response.statusCode == 200) {
                        let sessionId = request.getResponseHeader('SESSION_ID');
                        window.localStorage.setItem('sessionId', sessionId);
                        let payload = response.payload;
                    
                        window.localStorage.setItem('fullreg-country', payload.country);
                        window.localStorage.setItem('fullreg-role', payload.role);
                        window.localStorage.setItem('fullreg-payment-status', payload.paymentStatus);
                        window.localStorage.setItem('fullreg-is-submitted', payload.is_submitted);
                        window.localStorage.setItem('fullreg-receipt-url', payload.receipt_url);
                        window.localStorage.setItem('fullreg-deadline', payload.deadline);
                        //console.log(payload);
                        window.location = 'fullreg';
                    } else {
                        $('#feedback').text(response.statusDescription).css('color', 'red');
                    }

                },
                error: (err) => {
                    $('#feedback').text(responseText);
                },
                dataType: 'json'
            });
    }

    loginContent(json) {
        $.ajax({
            type: "POST",
            url: "/api/v1/auth/login",
            data: json,
            contentType: "application/json; charset=utf-8",
            success: (response, status, request) => {
                if (response.statusCode == 200) {
                    $('#feedback').text('Success login as admin').css('color', 'green');;
                    let roles = response.payload.roles;
                    let isAuthContent = false;
                    roles.forEach((role) => {
                        if(role === 'content') {
                            window.localStorage.setItem('role', role);
                        }
                    })
                    let sessionId = request.getResponseHeader('SESSION_ID');
                    window.localStorage.setItem('sessionId', sessionId);
                } else {
                    $('#feedback').text(response.statusDescription).css('color', 'red');
                }

            },
            error: (err) => {
                $('#feedback').text(responseText);
            },
            dataType: 'json'
        });
    }

}