import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractBinderFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        this.category = $addNewButton.data('category');
        this.initClickEvents();
        return this;
    }

    bindStandaloneGalleryUploader(standaloneFileUploader) {
        this.standaloneFileUploader = standaloneFileUploader;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
        this.$addNewButton.click(() => {
            this.standaloneFileUploader.category = this.category;
            $('#pop-up-gallery-title').text(`Upload Media for ${this.category}`);
        });
    }    
}
