import $ from "jquery";
import AbstractEditorFactory from "./abstract";

export default class AbstractEditableFactory extends AbstractEditorFactory {

    constructor(editButtonId) {
        super(editButtonId);
        this.initAdditionalVariables(editButtonId);
        this.initEvents();
        return this;
    }

    initAdditionalVariables(editButtonId) {
        let editButton = $(`#${editButtonId}`);
        editButton.css('display', 'initial');
        this.$editButton = editButton;

        this.id = editButton.data('id');

        let titleId = editButton.data('title-id');
        this.$title = $(`#${titleId}`);

        let inputTitleId = editButton.data('input-title-id');
        this.$inputTitle = $(`#${inputTitleId}`);
        
        this.textAreaId = editButton.data('textarea-id');

        let textAreaWrapperId = editButton.data('textarea-wrapper-id');
        this.$textAreaWrapper = $(`#${textAreaWrapperId}`);
        
        let target =  editButton.data('target-id');
        this.$target = $(`#${target}`);
 
        let feedbackId = editButton.data('feedback-id');
        this.$feedback= $(`#${feedbackId}`);

        let saveButtonId = editButton.data('save-button-id');
        this.$saveButton = $(`#${saveButtonId}`);

        let domfileId = editButton.data('domfile-id');
        this.domFile = document.getElementById(domfileId);
        this.$hiddenInputImage = $(`#${domfileId}`);
        this.postURL = ServerVariables.URL + '/api/v1/content/edit';
    }

    initEvents() {
        this.initEditableEditor();
        this.initSaveButton();
        this.initUploadImage();
    }

    initEditableEditor() {
        let isEditModeTurnOn = false;
        let _this = this;
        this.$editButton.click(function() {
            let targetVal = _this.$target.html();
            if(!isEditModeTurnOn) {
                isEditModeTurnOn = true;
                _this.activateEditorDisplay();
                _this.initEditor();
            } else {
                isEditModeTurnOn = false;
                _this.deactivateEditorDisplay();
            }
        })
    }

    initEditor() {
        tinymce.init({
            selector: `#${this.textAreaId}`,
            plugins: ['image', 'link', 'table', 'lists'],
            height: '500px',
            file_browser_callback: function(field_name, url, type, win) { },
            style_formats_merge: true,
            style_formats: [
                {title: 'Image Left', selector: 'img', styles: {
                    'float' : 'left',
                    'margin': '0 10px 0 10px'
                }},
                {title: 'Image Right', selector: 'img', styles: {
                    'float' : 'right',
                    'margin': '0 10px 0 10px'
                }}
            ]
        });
        tinyMCE.get(this.textAreaId).setContent(this.$target.html());
        this.initUploadImage(); 
    }

    activateEditorDisplay() {
        this.$textAreaWrapper.css('display', 'block');
        this.$target.css('display', 'none');
        this.$title.css('display', 'none');
        this.$inputTitle.css('display', 'inline-block').val(this.$title.text());        
        this.$saveButton.css('display', 'block');
    }

    deactivateEditorDisplay() {
        this.$textAreaWrapper.css('display', 'none');
        this.$target.css('display', 'block');
        this.$title.css('display', 'inline-block');
        this.$inputTitle.css('display', 'none');
        this.$saveButton.css('display', 'none');
    }

    getPayload() {
        return JSON.stringify({
            id: this.id,
            category: this.category,
            title: this.$inputTitle.val(),
            content: this.content
        })
    }

}
