import $ from "jquery";

export default class TopNavigation {

    constructor(callback) {
        this.$navTop = $('#nav-top');
        this.renderNavTop(callback);
    }

    renderNavTop(sideNavInitCallback) {
        let $navTop = $('#nav-top');
        this.changeNavColorByBrightness();
        this.initNavTopEvent();
        sideNavInitCallback();
        this.ifHasLoggedInShowLogut();
        this.initLogoutEvent();
    }

    changeNavColorByBrightness() {
        let dataActive = this.$navTop.data('active');
        let activeNavId = `#nav-top-${dataActive}`;
        $(activeNavId).addClass('active');
        if (this.$navTop.data('brightness')) {
            this.$navTop.addClass('nav-dark');
        }
    }

    initNavTopEvent() {
        $('.nav-top-sub').hover(function () {
            $(this).css('opacity', '1');
        }, function () {
            $(this).css('opacity', '0');
        });

        $('.nav-top-expandable').hover(function () {
            $(this).find('ul').css('opacity', '1');
        }, function () {
            $(this).find('ul').css('opacity', '0');
        })
    }

    ifHasLoggedInShowLogut() {
        this.isLoggedIn = false;
        console.log(ServerVariables.Auth.role);
        if (ServerVariables.Auth.role == 'content') {
            let link = $('#nav-top-login-anchor');
            link.text('Logout');
            link.attr('href',  ServerVariables.URL + '/logout');
            this.isLoggedIn = true;
        }
    }

    initLogoutEvent() {
        let sessionId = window.localStorage.getItem('sessionId');
        let json = JSON.stringify({
            sessionId: sessionId
        });

        $('#nav-top-login-anchor').click((event) => {
            event.preventDefault();
            if(this.isLoggedIn) {
                $.ajax({
                    type: "POST",
                    url: ServerVariables.URL + "/api/v1/auth/logout",
                    data: json,
                    contentType: "application/json; charset=utf-8",
                    success: (response) => {
                        window.localStorage.clear();
                        window.location.reload();
                    },
                    error: (err) => { },
                    dataType: 'json'
                });
            } else {
                window.location = 'login';
            }
        })
    }

    initWhiteBackgroundColorIfBright() {
        let navTopSub = document.getElementsByClassName('nav-top-sub');
        window.setTimeout(() => {
            for(let i = 0; i < navTopSub.length; i++) {
                let sub = navTopSub[i];
                sub.style.backgroundColor = 'white';
            }
        }, 1000);
    }

    disableNavIfDetailPage() {
        $('.nav-top-sub').css('display', 'none');
        $('#detail-page').hover(() => {
            $('.nav-top-sub').css('display', 'none');
        }, () => {
            $('.nav-top-sub').css('display', 'block');
        });
        
        $('.edit-mode').hover(() => {
            $('.nav-top-sub').css('display', 'none');
        }, () => {
            $('.nav-top-sub').css('display', 'block');
        });
    }

}