import $ from "jquery";

export default class AbstractFileUploaderFactory {

    constructor() {
        this.domFileImage;
        this.$inputBrightness = $('#input-slider-brightness');
        this.$submitButton = $('#input-slider-submit');
        this.$feedback = $('#input-slider-feedback') ;
        this.postURL = ServerVariables.URL + 'api/v1/content/addSlider';
        this.initSaveButton();
        return this;
    }

    initSaveButton() {
        this.$submitButton.click((event) => {
            event.preventDefault();

            var formData = new FormData();
            this.domFileImage = document.getElementById('input-slider-image');
            formData.append('brightness', this.$inputBrightness.val());
            formData.append('image', this.domFileImage.files[0]);
            formData.append('category', this.category);

            this.$feedback.text('saving...');
            $.ajax({
                url: this.postURL,
                type: 'POST',
                headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
                success: (response) => this.saveButtonSuccessCallback(response),
                error: () => this.$feedback.text('Something Went Wrong').addClass('failed'),
                dataType: 'json',
                data: formData,
                processData: false,
                contentType: false
            });
            this.$submitButton.prop('disabled' , true);
        });
    }

    saveButtonSuccessCallback(response) {
        if (response.statusCode == 200) {
            this.$submitButton.prop('disabled' , false);
            this.$feedback.text('Success').addClass('success feedback-success');
            // window.setTimeout(() => {
            //     window.location.reload();
            // }, 3000);
        } else {
             this.$feedback.text('Something Went Wrong').addClass('failed')
        }
    }

}
