import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractBinderFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        this.initClickEvents();
        return this;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
    }   

    bindStandaloneSubmenu(standaloneSubMenu) {
        this.standaloneSubMenu = standaloneSubMenu;
    }

}
