import $ from "jquery";

export default class PopUp {

    constructor(factory) {
        this.factory = factory;
        this.$popUp = $('#pop-up-cover');
        this.$popUpClose = $('#pop-up-cover-close');
        this.initPopUpEvents();
    }

    initPopUpEvents() {
        this.factory.$trigger.click(() => this.openPopUp());
        this.$popUpClose.click(() => this.closePopUp());
    }

    openPopUp() {
        this.$popUp.css('display', 'initial');
        let top = this.factory.$trigger[0].offsetTop;
        this.$popUp.css('top', top + 'px');
    }

    closePopUp() {
        this.$popUp.css('display', 'none');
    }

}