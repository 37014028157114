import $ from "jquery";
import ScriptController from "./features/publication/scriptController";
import TopNavigation from "./features/publication/components/topNavigation";
import SideNavigation from "./features/publication/components/sideNavigation";
import VideoBackground from "./features/publication/components/VideoBackground";
import Sitemap from "./features/publication/components/Sitemap";
import GATracker from "./features/publication/gaTracker"
import Auth from "./features/publication/components/auth";
import Slider from "./features/publication/slider/factory";
import PopUpFixed from "./features/publication/components/popUpFixed";

import AbstractEditorCreational from "./features/publication/editor/_abstract/abstract_creational";
import AbstractEditorCreationalMultipleStandalone from "./features/publication/editor/_abstract/abstract_creational_multiple_standalone";
import AbstractEditorCreationalMultipleBinder from "./features/publication/editor/_abstract/abstract_creational_multiple_binder";
import AbstractEditorEditable from "./features/publication/editor/_abstract/abstract_editable";
import AbstractEditorDeletable from "./features/publication/editor/_abstract/abstract_deletable";
import AbstractSitemapEditor from "./features/publication/editor/_abstract/abstract_sitemap";

import AbstractFIleUploaderStandalone from "./features/publication/fileUploader/_abstract";
import AbstractFIleUploaderBinder from "./features/publication/fileUploader/_abstract_binder";
import AbstractFileUploaderDeletable from "./features/publication/fileUploader/_abstract_deletable";

import AbstractGalleryUploaderStandalone from "./features/publication/galleryUploader/_abstract";
import AbstractGalleryUploaderBinder from "./features/publication/galleryUploader/_abstract_binder";
import AbstractGalleryUploaderDeletable from "./features/publication/galleryUploader/_abstract_deletable";

import AbstractAlbumUploaderStandalone from "./features/publication/albumUploader/_abstract";
import AbstractAlbumUploaderBinder from "./features/publication/albumUploader/_abstract_binder";
import AbstractAlbumUploaderDeletable from "./features/publication/albumUploader/_abstract_deletable";

import AbstractCoverUploaderStandalone from "./features/publication/coverUploader/_abstract";
import AbstractCoverUploaderBinder from "./features/publication/coverUploader/_abstract_binder";

import AbstractSliderUploaderStandalone from "./features/publication/slider/_abstract";
import AbstractSliderUploaderBinder from "./features/publication/slider/_abstract_binder";

import AbstractSubmenuStandalone from "./features/publication/submenu/_abstract";
import AbstractSubmenuBinder from "./features/publication/submenu/_abstract_binder";

$(document).ready(() => {

    let scriptController  = new ScriptController();

    let nav = new TopNavigation(() => {
         new SideNavigation();
    });

    new Sitemap();

    scriptController.ifRoleIsContent(() => {
         new AbstractSitemapEditor(); 
         createSubmenuInstances();
         createCoverUploaderInstances();
    });
   
    new GATracker().trackPublicationPage();

    scriptController.ifPage('home', () => {
        new Slider().withDomId('ipho-2017').construct();
        $('.clickable-fullscreen-detail').each(function() {
            new PopUpFixed($(this));
        });
        scriptController.ifRoleIsContent(() => {
            createEditorInstances();
            createSliderUploaderInstances();
        });
    });

    scriptController.ifPage('about-ipho', () => {
        new Slider().withDomId('cover').construct();
        scriptController.ifRoleIsContent(() =>{ 
            createEditorInstances();
            createFileUploaderInstances();
            createSliderUploaderInstances();
            createDeletableEditorInstances();
            createFileUploaderDeletableInstances();
        });
    });

    scriptController.ifPage('yogyakarta', () => {
        new VideoBackground().addMuteButton();
        scriptController.ifRoleIsContent(() => { 
            createEditorInstances()
            createDeletableEditorInstances();
        });
    });

    scriptController.ifPage('ipho2017', () => {
        new Slider().withDomId('cover-ipho2017').construct();
        scriptController.ifRoleIsContent(() =>  {
            createEditorInstances();
            createSliderUploaderInstances();
            createDeletableEditorInstances();
        });
    });

    scriptController.ifPage('news', () => {
        new Slider().withDomId('cover').construct();
        scriptController.ifRoleIsContent(() =>{ 
            createEditorInstances();
            createFileUploaderInstances();
            createSliderUploaderInstances();
            createDeletableEditorInstances();
            createFileUploaderDeletableInstances();
        });
    });

    scriptController.ifPage('gallery', () => {
        new Slider().withDomId('cover').construct();
        $('.clickable-fullscreen-detail').each(function() {
            new PopUpFixed($(this));
        });

        scriptController.ifRoleIsContent(() =>{ 
            createGalleryUploaderInstances();
            createDeletableGalleryInstances();
            createSliderUploaderInstances();
        });
    });

    scriptController.ifPage('album-detail', () => {
        $('.clickable-fullscreen-detail').each(function() {
            new PopUpFixed($(this));
        });
        scriptController.ifRoleIsContent(() =>{ 
            createAlbumUploaderInstances();
            createDeletableAlbumInstances();
        });
    });

    scriptController.ifPage('login', () => {
        new Auth().initEvents();
    });

    scriptController.ifPage('detail', () => {
        scriptController.ifRoleIsContent(() => {
            $('.content-button').each(function() {
                let $button = $(this);
                new AbstractEditorEditable($button.attr('id'));
            });
            nav.initWhiteBackgroundColorIfBright();
            nav.disableNavIfDetailPage();
            createDeletableEditorInstances();
        });
    });

    function createEditorInstances() {
        $('.content-button').each(function() {
            let $button = $(this);
            new AbstractEditorEditable($button.attr('id'));
        });

        let standaloneEditor = new AbstractEditorCreationalMultipleStandalone();
        $('.category-button').each(function() {
            let $button = $(this);
            new AbstractEditorCreationalMultipleBinder($button)
                .bindStandaloneEditor(standaloneEditor);
        });
    }

    function createDeletableEditorInstances() {
        $('.content-delete-button').each((function() {
            new AbstractEditorDeletable($(this));
        }));
    }

    function createFileUploaderDeletableInstances() {
        $('.file-upload-delete-button').each((function() {
            new AbstractFileUploaderDeletable($(this));
        }));
    }

    function createFileUploaderInstances() {
        let standAloneFileUploader = new AbstractFIleUploaderStandalone();
        $('.file-upload-trigger').each(function() {
            let $button = $(this);
            new AbstractFIleUploaderBinder($button).bindStandaloneFileUploader(standAloneFileUploader);
        });
    }

    function createGalleryUploaderInstances() {
        let standAloneGalleryUploader = new AbstractGalleryUploaderStandalone();
        $('.file-upload-trigger').each(function() {
            let $button = $(this);
            new AbstractGalleryUploaderBinder($button).bindStandaloneGalleryUploader(standAloneGalleryUploader);
        });
    }

    function createDeletableGalleryInstances() {
        $('.file-upload-delete-button').each((function() {
            new AbstractGalleryUploaderDeletable($(this));
        }));
    }

    function createAlbumUploaderInstances() {
        let standAloneAlbumUploader = new AbstractAlbumUploaderStandalone();
        $('.file-upload-trigger').each(function() {
            let $button = $(this);
            new AbstractAlbumUploaderBinder($button).bindStandaloneAlbumUploader(standAloneAlbumUploader);
        });
    }

    function createDeletableAlbumInstances() {
        $('.file-upload-delete-button').each((function() {
            new AbstractAlbumUploaderDeletable($(this));
        }));
    }

    function createCoverUploaderInstances() {
        let standAloneCoverUploader = new AbstractCoverUploaderStandalone();
        $('.content-upload-cover-button').each(function() {
            let $button = $(this);
            new AbstractCoverUploaderBinder($button).bindStandaloneCoverUploader(standAloneCoverUploader);
        });
    }

    function createSliderUploaderInstances() {
        let standAloneSliderUploader = new AbstractSliderUploaderStandalone();
        $('.slider-upload-trigger').each(function() {
            let $button = $(this);
            new AbstractSliderUploaderBinder($button).bindStandaloneSliderUploader(standAloneSliderUploader);
        });
    }

    function createSubmenuInstances() {
        let standAloneSubmenu = new AbstractSubmenuStandalone();
        $('.submenu-add-trigger').removeClass('hide');
        $('.submenu-add-trigger').each(function() {
            let $button = $(this);
            new AbstractSubmenuBinder($button).bindStandaloneSubmenu(standAloneSubmenu);
        });
    }
    
})