import $ from "jquery";

export default class VideoBackground {

	constructor() {
		return this;
	}

	addMuteButton() {
        $('#sound-button').click(function () {
	        var video = $('video');
	        if(video[0].muted) {
	            video[0].muted = false;
				$(this).text('Mute');
	        } else {
	            video[0].muted = true;
				$(this).text('Unmute');
	        }
        })
	}
}