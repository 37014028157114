import $ from "jquery";
import AbstractEditorFactory from "./abstract__standalone";

export default class AbstractCreationalFactory extends AbstractEditorFactory {

    constructor() {
        super();
        this.initAdditionalVariables();
        this.initEvents();
        return this;
    }

    initAdditionalVariables() {
        this.domFile = document.getElementById("input-image-creational");
        this.$inputTitle = $('#input-title-creational');
        this.$hiddenInputImage = $('#input-image-creational');
        this.$saveButton = $('#save-button-creational');
        this.$feedback = $('#form-feedback-creational'); 
        this.postURL = '/api/v1/content/add';
        this.textAreaId = 'editor-creational-textarea';
        this.category = '';
        $('#pop-up-title').text(`Add ${this.category}`);
    }

    initEvents() {
        this.initEditor();
        this.initSaveButton();
        this.initUploadImage();
    }

    initEditor() {
        tinymce.init({
            selector: '#editor-creational-textarea',
            plugins: ['image', 'link', 'table', 'lists'],
            height: '500px',
            file_browser_callback: function(field_name, url, type, win) { },
            style_formats_merge: true,
            style_formats: [
                {title: 'Image Left', selector: 'img', styles: {
                    'float' : 'left',
                    'margin': '0 10px 0 10px'
                }},
                {title: 'Image Right', selector: 'img', styles: {
                    'float' : 'right',
                    'margin': '0 10px 0 10px'
                }}
            ]
        });
    }    

    resetAll() {
        this.resetFileInput();
        this.$inputTitle.val("");
        tinyMCE.get(this.textAreaId).setContent("");
    }
    
    
}


