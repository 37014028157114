import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractBinderFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        this.articleId = $addNewButton.data('id');
        this.initClickEvents();
        return this;
    }

    bindStandaloneCoverUploader(standaloneCoverUploader) {
        this.standaloneCoverUploader = standaloneCoverUploader;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
        this.$addNewButton.click(() => {
            this.standaloneCoverUploader.articleId = this.articleId;
            $('#pop-up-cover-title').text(`Add Cover for Article Id ${this.articleId}`);
        });
    }    
}
