import $ from "jquery";
import PopUp from "./popUp";

export default class AbstractBinderFactory {

    constructor($addNewButton) {
        this.$addNewButton = $addNewButton;
        this.$trigger = $addNewButton;
        this.$trigger.css('display', 'inline-block');
        this.albumId = $addNewButton.data('album-id');
        this.initClickEvents();
        return this;
    }

    bindStandaloneAlbumUploader(standaloneAlbumUploader) {
        this.standaloneAlbumUploader = standaloneAlbumUploader;
    }

    initClickEvents() {
        this.popUp = new PopUp(this);
        this.$addNewButton.click(() => {
            this.standaloneAlbumUploader.albumId = this.albumId;
            $('#pop-up-gallery-title').text(`Upload Media for ${this.category}`);
        });
    }    
}
