import $ from "jquery";

export default class AbstractSubMenuFactory {

    constructor() {
        this.$menu = $('#input-submenu-menu');
        this.$name = $('#input-submenu-name');
        this.$submitButton = $('#input-submenu-submit');
        this.$feedback = $('#input-submenu-feedback') ;
        this.postURL = ServerVariables.URL + 'api/v1/menu/addSubMenu';
        this.deleteURL = ServerVariables.URL + 'api/v1/menu/deleteSubmenu';
        this.initSaveButton();
        this.initMoveUp();
        this.initDelete();
        return this;
    }

    initSaveButton() {
        this.$submitButton.click((event) => {
            event.preventDefault();

            var formData = new FormData();
            formData.append('menu', this.$menu.val());
            formData.append('name', this.$name.val());

            this.$feedback.text('saving...');
            $.ajax({
                url: this.postURL,
                type: 'POST',
                headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
                success: (response) => this.saveButtonSuccessCallback(response),
                error: () => this.$feedback.text('Something Went Wrong').addClass('failed'),
                dataType: 'json',
                data: formData,
                processData: false,
                contentType: false
            });
            this.$submitButton.prop('disabled' , true);
        });
    }

    saveButtonSuccessCallback(response) {
        if (response.statusCode == 200) {
            this.$submitButton.prop('disabled' , false);
            this.$feedback.text('Success').addClass('success feedback-success');
            window.setTimeout(() => {
                window.location.reload();
            }, 3000);
        } else {
             this.$feedback.text('Something Went Wrong').addClass('failed')
        }
    }

    initMoveUp() {
        let _this = this;
        $('.submenu-move-top').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
                _this.swapPrev($(this));
            });
        });

        $('.submenu-move-bottom').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
                _this.swapNext($(this));
            });
        });
    }

    swapPrev($this) {
        let $input = $this.parent().find('input');
        let $inputId = $input.data('id');
        let $inputVal = $input.val();
        let $inputUrl = $input.data('url');

        let $li = $this.parent().parent();
        let $prevLi = $li.prev($('li')); 
        let $prevInput = $prevLi.find('a > input');
        let $prevInputId = $prevInput.data('id');
        let $prevInputVal = $prevInput.val();
        let $prevInputUrl = $prevInput.data('url');
        if ($prevInputId) {
            let tempInputId = $inputId;
            let tempInputVal = $inputVal;
            let tempInputUrl = $inputUrl;

            $input.data('id', $prevInputId);
            $input.data('url', $prevInputUrl);
            $input.val($prevInputVal);

            $prevInput.data('id', tempInputId);
            $prevInput.data('url', tempInputUrl);
            $prevInput.val(tempInputVal);
        }
    }

    swapNext($this) {
        let $input = $this.parent().find('input');
        let $inputId = $input.data('id');
        let $inputVal = $input.val();
        let $inputUrl = $input.data('url');

        let $li = $this.parent().parent();
        let $nextLi = $li.next($('li')); 
        let $nextInput = $nextLi.find('a > input');
        let $nextInputId = $nextInput.data('id');
        let $nextInputVal = $nextInput.val();
        let $nextInputUrl = $nextInput.data('url');
        if ( $nextInputId) {
            let tempInputId = $inputId;
            let tempInputVal = $inputVal;
            let tempInputUrl = $inputUrl;

            $input.data('id', $nextInputId);
            $input.data('url', $nextInputUrl);
            $input.val($nextInputVal);

            $nextInput.data('id', tempInputId);
            $nextInput.data('url', tempInputUrl);
            $nextInput.val(tempInputVal);
        }
    }

    initDelete() {
        let _this = this;
        $('.submenu-delete').each(function() {
            $(this).click(function(event) {
                event.preventDefault();
                let id = $(this).data('id');
                let confirm = window.confirm("Are you sure?");
                if (confirm) {
                    $.ajax({
                        url: _this.deleteURL + '/' + id,
                        type: 'DELETE',
                        headers: { 'SESSION_ID': window.localStorage.getItem('sessionId') },
                        success: (response) => { 
                            window.alert('success')
                            window.location.reload();
                        },
                        error: () => {},
                        contentType: 'json'
                    });
                }
            });
        });
    }

}
